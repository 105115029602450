@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.animation2 {
  animation: shake 3s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.swipe {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}

.tinderCard {
  position: relative;
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(139, 174, 185, 0.4688195474777448) 98%
  ); */
}

.cardContainer {
  position: relative;
  width: 90vw;
  max-width: 370px;
  height: 300px;
}

.typewriter {
  display: inline-block;
  overflow: hidden;
  border-right: .15em solid black;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation: typing 3.5s steps(20, end) forwards, blink-caret .75s step-end infinite;
}

@keyframes typing {
  from { width: 0; }
  to { width: 30ch; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}


/* Add this CSS to your styles */
.input-group {
  width: 100%;
}

.input-group .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .dropdown-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
/*GAME 2*/
.game-container {
  position: relative;
  width: 90%;
  height: 250px;
  overflow: hidden;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
}

.target {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: red;
  /* background: url('./dino.png') no-repeat center center / contain; */
  border-radius: 50%;
  cursor: pointer;
}

.start-screen, .game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.score {
  position: absolute;
  top: 10px;
  left: 10px;
  color: black;
}

